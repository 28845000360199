import React, { useContext, useState } from "react";
import "./index.scss";
import PropTypes from "prop-types";
import ControlsEmailCollectContainer from "../../../../containers/common/forms/ControlsEmailCollectContainer";
import {
  BUTTON_BACKGROUND,
  BUTTON_SIZE,
  CONTROLS_EMAIL_COLLECT_POSITION,
  PAGE_OPTIONS,
  PAGES,
} from "../../../../helpers/constants";
import RedirectedButton from "../../../common/Forms/emailCollectRedirectedButton";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";

export const EmailCollectBlock = ({ emailCollectRef }) => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const [isShowText, setIsShowText] = useState(false);

  return (
    <section className="emailCollectBlock">
      <div ref={emailCollectRef} className="emailCollectBlock-scrollPoint" />
      <div className="container">
        <div className="emailCollectBlock__title">
          Try AMZScout Bundle for Free!
        </div>
        {isAuth ? (
          <RedirectedButton
            href={PAGE_OPTIONS[PAGES.LP_HOW_SELL].REDIRECTED_URL}
            title="Try For Free"
            background={BUTTON_BACKGROUND.ORANGE}
            isTopText={false}
            isShowText={isShowText}
          />
        ) : (
          <ControlsEmailCollectContainer
            page={PAGES.LP_HOW_SELL}
            btnText="Get started for FREE"
            btnSize={BUTTON_SIZE.L}
            position={CONTROLS_EMAIL_COLLECT_POSITION.CENTER}
            onSuccess={() => setIsShowText(true)}
          />
        )}
        <div
          className="emailCollectBlock__small-text"
          style={isAuth ? { marginTop: "16px" } : {}}
        >
          <b>You’ll get access to: PRO Extension (15 trial search runs)</b>
        </div>
        <div className="emailCollectBlock__text">
          Step-by-Step Course, Product Database, Amazon Keyword Search & other
          Keyword tools
        </div>
      </div>
    </section>
  );
};

EmailCollectBlock.propTypes = {
  emailCollectRef: PropTypes.any,
};

export default EmailCollectBlock;
